import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import None from "../Assets/character/none.PNG";
import Morty from "../Assets/character/morty.PNG";
import Meeseeks from "../Assets/character/meeseek.PNG";
import MoreMeeseeks from "../Assets/character/meeseek-more.PNG";
import Pickle from "../Assets/character/pickle.PNG";

type CharacterProps = {
  character: string;
};

export const Character = (props: CharacterProps) => {
  const pic = getPic(props.character);
  return (
    <div>
      <Flex justifyContent="center">
        <Image position="absolute" boxSize="65vh" src={pic} />
      </Flex>
    </div>
  );
};

const getPic = (pic: string) => {
  if (pic === "Morty") {
    return Morty;
  }
  if (pic === "None") {
    return None;
  }
  if (pic === "Meeseek") {
    return Meeseeks;
  }
  if (pic === "More-Meeseek") {
    return MoreMeeseeks;
  }
  if (pic === "Pickle") {
    return Pickle;
  }
  return None;
};
