import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Background } from "./Background";
import { Body } from "./Body";
import { Character } from "./Character";
import { Face } from "./Face";
import { Hair } from "./Hair";

const Rick = () => {
  const [background, setBackground] = useState("Navy");
  const [body, setBody] = useState("Classic");
  const [character, setCharacter] = useState("None");
  const [face, setFace] = useState("Bored");
  const [hair, setHair] = useState("Classic");
  return (
    <div>
      <Tabs isFitted variant="enclosed" align="center">
        <TabList>
          <Tab>Background</Tab>
          <Tab>Body</Tab>
          <Tab>Face</Tab>
          <Tab>Hair</Tab>
          <Tab>Character</Tab>
        </TabList>

        {/* Background Atributes */}
        <TabPanels>
          <TabPanel>
            <Flex gap="5px" flexWrap="wrap" justifyContent="center">
              <Button
                bg={background === "Navy" ? "blue.200" : undefined}
                onClick={() => setBackground("Navy")}
              >
                Navy
              </Button>
              <Button
                bg={background === "Red" ? "blue.200" : undefined}
                onClick={() => setBackground("Red")}
              >
                Red
              </Button>
              <Button
                bg={background === "Purple" ? "blue.200" : undefined}
                onClick={() => setBackground("Purple")}
              >
                Purple
              </Button>
              <Button
                bg={background === "Yellow" ? "blue.200" : undefined}
                onClick={() => setBackground("Yellow")}
              >
                Yellow
              </Button>
              <Button
                bg={background === "Royal" ? "blue.200" : undefined}
                onClick={() => setBackground("Royal")}
              >
                Royal
              </Button>
              <Button
                bg={background === "Portal" ? "blue.200" : undefined}
                onClick={() => setBackground("Portal")}
              >
                Portal
              </Button>
              <Button
                bg={background === "Red-Portal" ? "blue.200" : undefined}
                onClick={() => setBackground("Red-Portal")}
              >
                Red Portal
              </Button>
              <Button
                bg={background === "Portal-Glitch" ? "blue.200" : undefined}
                onClick={() => setBackground("Portal-Glitch")}
              >
                Glitched Portal
              </Button>
              <Button
                bg={background === "Black-Glitch" ? "blue.200" : undefined}
                onClick={() => setBackground("Black-Glitch")}
              >
                Glitched Black
              </Button>
            </Flex>
          </TabPanel>

          {/* Body Atributes */}
          <TabPanel>
            <Flex gap="5px" flexWrap="wrap" justifyContent="center">
              <Button
                bg={body === "Classic" ? "blue.200" : undefined}
                onClick={() => setBody("Classic")}
              >
                Classic
              </Button>
              <Button
                bg={body === "Classic-Glitch" ? "blue.200" : undefined}
                onClick={() => setBody("Classic-Glitch")}
              >
                3D
              </Button>
              <Button
                bg={body === "Classic-Glow" ? "blue.200" : undefined}
                onClick={() => setBody("Classic-Glow")}
              >
                Glow
              </Button>
              <Button
                bg={body === "Birdy" ? "blue.200" : undefined}
                onClick={() => setBody("Birdy")}
              >
                Birdy
              </Button>
              <Button
                bg={body === "Birdy-Glitch" ? "blue.200" : undefined}
                onClick={() => setBody("Birdy-Glitch")}
              >
                Glitched Birdy
              </Button>
              <Button
                bg={body === "Bloody" ? "blue.200" : undefined}
                onClick={() => setBody("Bloody")}
              >
                Bloody
              </Button>
              <Button
                bg={body === "Gun" ? "blue.200" : undefined}
                onClick={() => setBody("Gun")}
              >
                3 Arms
              </Button>
            </Flex>
          </TabPanel>

          {/* Face Atributes */}
          <TabPanel>
            <Flex gap="5px" flexWrap="wrap" justifyContent="center">
              <Button
                bg={face === "Bored" ? "blue.200" : undefined}
                onClick={() => setFace("Bored")}
              >
                Bored
              </Button>
              <Button
                bg={face === "Blazed" ? "blue.200" : undefined}
                onClick={() => setFace("Blazed")}
              >
                Blazed
              </Button>
              <Button
                bg={face === "Crazy" ? "blue.200" : undefined}
                onClick={() => setFace("Crazy")}
              >
                Crazy
              </Button>
              <Button
                bg={face === "Crazy-Glitch" ? "blue.200" : undefined}
                onClick={() => setFace("Crazy-Glitch")}
              >
                Glitched Crazy
              </Button>
              <Button
                bg={face === "Glasses" ? "blue.200" : undefined}
                onClick={() => setFace("Glasses")}
              >
                Glasses
              </Button>
              <Button
                bg={face === "Hat" ? "blue.200" : undefined}
                onClick={() => setFace("Hat")}
              >
                Hat
              </Button>
              <Button
                bg={face === "Melting" ? "blue.200" : undefined}
                onClick={() => setFace("Melting")}
              >
                Melting
              </Button>
              <Button
                bg={face === "Melting-Glitch" ? "blue.200" : undefined}
                onClick={() => setFace("Melting-Glitch")}
              >
                Glitched Melting
              </Button>
              <Button
                bg={face === "Oops" ? "blue.200" : undefined}
                onClick={() => setFace("Oops")}
              >
                Oops
              </Button>
              <Button
                bg={face === "Rainbow" ? "blue.200" : undefined}
                onClick={() => setFace("Rainbow")}
              >
                Rainbow Barf
              </Button>
              <Button
                bg={face === "Rainbow-Glitch" ? "blue.200" : undefined}
                onClick={() => setFace("Rainbow-Glitch")}
              >
                Glitched Rainbow
              </Button>
              <Button
                bg={face === "Smirk" ? "blue.200" : undefined}
                onClick={() => setFace("Smirk")}
              >
                Smirk
              </Button>
              <Button
                bg={face === "Smirk-Glitch" ? "blue.200" : undefined}
                onClick={() => setFace("Smirk-Glitch")}
              >
                Glitched Smirk
              </Button>
            </Flex>
          </TabPanel>

          {/* Hair Atributes */}
          <TabPanel>
            <Flex gap="5px" flexWrap="wrap" justifyContent="center">
              <Button
                bg={hair === "Classic" ? "blue.200" : undefined}
                onClick={() => setHair("Classic")}
              >
                Classic
              </Button>
              <Button
                bg={hair === "Classic-Glow" ? "blue.200" : undefined}
                onClick={() => setHair("Classic-Glow")}
              >
                Glow
              </Button>
              <Button
                bg={hair === "Classic-Glitch" ? "blue.200" : undefined}
                onClick={() => setHair("Classic-Glitch")}
              >
                Glitched
              </Button>
              <Button
                bg={hair === "Classic-Glitch2" ? "blue.200" : undefined}
                onClick={() => setHair("Classic-Glitch2")}
              >
                3D
              </Button>
              <Button
                bg={hair === "Frizzy" ? "blue.200" : undefined}
                onClick={() => setHair("Frizzy")}
              >
                Frizzy
              </Button>
            </Flex>
          </TabPanel>

          {/* Character Atributes */}
          <TabPanel>
            <Flex gap="5px" flexWrap="wrap" justifyContent="center">
              <Button
                bg={character === "None" ? "blue.200" : undefined}
                onClick={() => setCharacter("None")}
              >
                None
              </Button>
              <Button
                bg={character === "Morty" ? "blue.200" : undefined}
                onClick={() => setCharacter("Morty")}
              >
                Morty
              </Button>
              <Button
                bg={character === "Meeseek" ? "blue.200" : undefined}
                onClick={() => setCharacter("Meeseek")}
              >
                Mr Meeseeks
              </Button>
              <Button
                bg={character === "More-Meeseek" ? "blue.200" : undefined}
                onClick={() => setCharacter("More-Meeseek")}
              >
                More Mr Meeseeks
              </Button>
              <Button
                bg={character === "Pickle" ? "blue.200" : undefined}
                onClick={() => setCharacter("Pickle")}
              >
                Pickle Rick
              </Button>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Display Images */}
      <Background background={background} />
      <Character character={character} />
      <Body body={body} />
      <Hair hair={hair} />
      <Face face={face} />
    </div>
  );
};

export default Rick;
