import React from "react";
import Navy from "../Assets/background/navy.PNG";
import { Flex, Image } from "@chakra-ui/react";
import Red from "../Assets/background/red.PNG";
import Purple from "../Assets/background/purple.PNG";
import Yellow from "../Assets/background/yellow.PNG";
import Royal from "../Assets/background/royal.PNG";
import Portal from "../Assets/background/portal.PNG";
import RedPortal from "../Assets/background/portal-red.PNG";
import PortalGlitch from "../Assets/background/portal-glitch.PNG";
import BlackGlitch from "../Assets/background/black-glitch.PNG";

type BackgroundProps = {
  background: string;
};

export const Background = (props: BackgroundProps) => {
  const pic = getPic(props.background);
  return (
    <div>
      <Flex justifyContent="center">
        <Image position="absolute" boxSize="65vh" src={pic} />
      </Flex>
    </div>
  );
};

const getPic = (pic: string) => {
  if (pic === "Red") {
    return Red;
  }
  if (pic === "Purple") {
    return Purple;
  }
  if (pic === "Yellow") {
    return Yellow;
  }
  if (pic === "Royal") {
    return Royal;
  }
  if (pic === "Portal") {
    return Portal;
  }
  if (pic === "Red-Portal") {
    return RedPortal;
  }
  if (pic === "Portal-Glitch") {
    return PortalGlitch;
  }
  if (pic === "Black-Glitch") {
    return BlackGlitch;
  }
  return Navy;
};
