import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import Bored from "../Assets/face/bored.PNG";
import Blazed from "../Assets/face/blazed.PNG";
import Crazy from "../Assets/face/crazy.PNG";
import CrazyGlitch from "../Assets/face/crazy-glitch.PNG";
import Glasses from "../Assets/face/glasses.PNG";
import Hat from "../Assets/face/hat.PNG";
import Melting from "../Assets/face/melting.PNG";
import MeltingGlitch from "../Assets/face/melting-glitch.PNG";
import Oops from "../Assets/face/oops.PNG";
import Rainbow from "../Assets/face/rainbow.PNG";
import RainbowGlitch from "../Assets/face/rainbow-glitch.PNG";
import Smirk from "../Assets/face/smirk.PNG";
import SmirkGlitch from "../Assets/face/smirk-glitch.PNG";

type FaceProps = {
  face: string;
};

export const Face = (props: FaceProps) => {
  const pic = getPic(props.face);
  return (
    <div>
      <Flex justifyContent="center">
        <Image position="absolute" boxSize="65vh" src={pic} />
      </Flex>
    </div>
  );
};

const getPic = (pic: string) => {
  if (pic === "Bored") {
    return Bored;
  }
  if (pic === "Blazed") {
    return Blazed;
  }
  if (pic === "Crazy") {
    return Crazy;
  }
  if (pic === "Crazy-Glitch") {
    return CrazyGlitch;
  }
  if (pic === "Glasses") {
    return Glasses;
  }
  if (pic === "Hat") {
    return Hat;
  }
  if (pic === "Melting") {
    return Melting;
  }
  if (pic === "Melting-Glitch") {
    return MeltingGlitch;
  }
  if (pic === "Oops") {
    return Oops;
  }
  if (pic === "Rainbow") {
    return Rainbow;
  }
  if (pic === "Rainbow-Glitch") {
    return RainbowGlitch;
  }
  if (pic === "Smirk") {
    return Smirk;
  }
  if (pic === "Smirk-Glitch") {
    return SmirkGlitch;
  }
  return Bored;
};
