import { Image, Flex } from "@chakra-ui/react";
import React from "react";
import Classic from "../Assets/body/classic.PNG";
import ClassicGlitch from "../Assets/body/classic-glitch.PNG";
import ClassicGlow from "../Assets/body/classic-glow.PNG";
import Birdy from "../Assets/body/birdy.PNG";
import BirdyGlitch from "../Assets/body/birdy-glitch.PNG";
import Bloody from "../Assets/body/bloody.PNG";
import Gun from "../Assets/body/gun.PNG";

type BodyProps = {
  body: string;
};

export const Body = (props: BodyProps) => {
  const pic = getPic(props.body);
  return (
    <div>
      <Flex justifyContent="center">
        <Image position="absolute" boxSize="65vh" src={pic} />
      </Flex>
    </div>
  );
};

const getPic = (pic: string) => {
  if (pic === "Classic") {
    return Classic;
  }
  if (pic === "Classic-Glitch") {
    return ClassicGlitch;
  }
  if (pic === "Classic-Glow") {
    return ClassicGlow;
  }
  if (pic === "Birdy") {
    return Birdy;
  }
  if (pic === "Birdy-Glitch") {
    return BirdyGlitch;
  }
  if (pic === "Bloody") {
    return Bloody;
  }
  if (pic === "Gun") {
    return Gun;
  }
  return Classic;
};
