import { Image, Link, Text, Flex } from "@chakra-ui/react";
import React from "react";
import Rick from "./Components/Rick";
import Logo from "./Assets/Logo.PNG";

function App() {
  return (
    <div>
      <Flex justifyContent="center" alignItems="center" h="12vh">
        <Image h="10vh" src={Logo} />
      </Flex>

      <Rick />

      <Flex justifyContent="center" h="75vh" alignItems="end">
        <Text color="gray.300" position="absolute">
          Powered by{" "}
          <Link href="https://www.megan-cochran.com">Megan-Cochran</Link>
        </Text>
      </Flex>

      <Flex h="3vh" />
    </div>
  );
}

export default App;
