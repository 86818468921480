import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import Classic from "../Assets/hair/classic.PNG";
import ClassicGlow from "../Assets/hair/classic-glow.PNG";
import ClassicGlitch from "../Assets/hair/classic-glitch.PNG";
import ClassicGlitch2 from "../Assets/hair/classic-glitch2.PNG";
import Frizzy from "../Assets/hair/frizzy.PNG";

type HairProps = {
  hair: string;
};

export const Hair = (props: HairProps) => {
  const pic = getPic(props.hair);
  return (
    <div>
      <Flex justifyContent="center">
        <Image position="absolute" boxSize="65vh" src={pic} />
      </Flex>
    </div>
  );
};

const getPic = (pic: string) => {
  if (pic === "Classic") {
    return Classic;
  }
  if (pic === "Classic-Glow") {
    return ClassicGlow;
  }
  if (pic === "Classic-Glitch") {
    return ClassicGlitch;
  }
  if (pic === "Classic-Glitch2") {
    return ClassicGlitch2;
  }
  if (pic === "Frizzy") {
    return Frizzy;
  }
  return Classic;
};
